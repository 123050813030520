import React from 'react';



function Additions() {

  return (
    <div>
      Additions
    </div>
  );
}

export default Additions; 