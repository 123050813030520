import React from 'react';



function Remodels() {

  return (
    <div>
      Remodels
    </div>
  );
}

export default Remodels; 